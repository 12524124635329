import { Dictionary, IDictionary } from "../models";
import IFormValidator from "./IFormValidator";
import { IValidationSummary } from "./IValidationSummary";
import { IValidator } from "./IValidator";
import ValidatorFactory from "./ValidatorFactory";
import ServerSideValidator from "./validators/ServerSideValidator";

function toLowerCamelCase(str: string): string {
    // Split the string into words based on delimiters
    const words = str.split(/[-_ ]+/);

    // Convert the first word to lowercase
    const firstWord = words[0].toLowerCase();

    // Capitalize the first letter of each subsequent word
    const capitalizedWords = words.slice(1).map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    // Join the words together
    return firstWord + capitalizedWords.join("");
}

export default class FormValidator implements IFormValidator {
    private readonly _formId: string;
    private _form: HTMLFormElement;
    private _valid: boolean;

    // field validators by input id
    private _validators: IDictionary<IValidator[]> = new Dictionary<IValidator[]>();

    constructor(formId: string) {
        this._formId = formId;
    }

    public get formId() {
        return this._formId;
    }

    public get valid() {
        return this._valid;
    }

    public validate(): boolean {
        this._valid = true;

        this.setForm();

        const keys = this._validators.keys();

        for (let i = 0; i < keys.length; i++) {
            const validators = this._validators.item(keys[i]);

            for (let j = 0; j < validators.length; j++) {
                const validator = validators[j];
                const valid = validator.validate();

                if (valid === false) {
                    this._valid = false;

                    // stop validating the current input if it's already found to be invalid
                    break;
                }
            }
        }

        return this._valid;
    }

    public addModelStateErrors(modelState: any, validationSummary: IValidationSummary | null = null, namePrefix = "") {
        this.setForm();

        if (!modelState.errors) {
            return;
        }

        for (const name in modelState.errors) {
            if (!modelState.errors.hasOwnProperty(name)) {
                continue;
            }

            const errors: string[] = modelState.errors[name];

            if (!Array.isArray(errors)) {
                continue;
            }

            for (let i = 0; i < errors.length; i++) {
                const element = this._form.querySelector(`[name='${toLowerCamelCase(namePrefix + name)}']`) as
                    | HTMLInputElement
                    | HTMLSelectElement
                    | HTMLTextAreaElement;
                if (element) {
                    let validators = this._validators.item(element.id);
                    if (!validators) {
                        validators = [];
                        this._validators.add(element.id, validators);
                    }

                    validators.push(new ServerSideValidator(element, errors[i]));
                } else {
                    // model errors (not related to a found element)
                    if (validationSummary) {
                        if (!validationSummary.errors) {
                            validationSummary.errors = [];
                        }
                        validationSummary.errors.push(errors[i]);
                    } else {
                        const errorMessages = errors.join(" ");
                        throw new Error(`Missing validation summary. Errors: ${errorMessages}`);
                    }
                }
            }
        }
    }

    public addModelStateErrorsPromise(
        modelState: any,
        validationSummary: IValidationSummary | null = null,
        namePrefix = ""
    ): Promise<any> {
        this.addModelStateErrors(modelState, validationSummary, namePrefix);
        return Promise.resolve(modelState);
    }

    private setForm() {
        if (!this._form && this._formId) {
            this._form = document.getElementById(this._formId) as HTMLFormElement;
        }

        if (this._form) {
            this._validators.clear();
            this.addValidators();
        }
    }

    private addValidators() {
        const elements = this._form.querySelectorAll("input, select, textarea");
        if (!elements) {
            return;
        }

        for (let i = 0; i < elements.length; i++) {
            const element = elements.item(i) as HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

            if (element) {
                const validators = ValidatorFactory.getValidators(element);
                if (validators.length > 0) {
                    const existingValidators = this._validators.item(element.id);
                    if (existingValidators) {
                        this._validators.remove(element.id);
                    }

                    this._validators.add(element.id, validators);

                    if (existingValidators) {
                        // append the manually added existing validators to the found validators
                        for (let j = 0; j < existingValidators.length; j++) {
                            validators.push(existingValidators[j]);
                        }
                    }
                }
            }
        }
    }
}
