import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "./assets/styles/theme/index.scss";
import "./assets/styles/app.scss";
import "./assets/styles/bootstrap/index.scss";
import "./assets/styles/bootstrap-dark/index.scss";

import Vue from "vue";

import { restClient } from "./ajax";
import App from "./App.vue";
import configure from "./config";
import router, { routeNames, routePaths } from "./router";
import store, { AppActionTypes } from "./store";

configure();

store.dispatch(AppActionTypes.getSettings).then(() => {
    // redirect to login if the user is not authenticated
    if (!store.state.settings.isAuthenticated) {
        // https://dev.azure.com/elitedev/Elite.Wiki/_wiki/wikis/Elite/728/Authentication-Expiration
        window.location.href =
            store.state.settings.signInUrl +
            "?redirectUri=" +
            encodeURIComponent(window.location.pathname);
        return;
    }

    new Vue({
        store: store,
        router: router,
        provide: { routeNames, routePaths, restClient },
        render: (h) => h(App)
    }).$mount("#app");
});
